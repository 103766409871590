//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CryptoJS from "crypto-js";
import { loginApi, forget } from "../api";
import { mapMutations } from "vuex";

export default {
  name: "Login",
  props: ["show"],
  data() {
    return {
      isLoading: false,
      lgShow: "login",
      form: {
        name: "",
        password: "",
      },
      form2: {
        email: "",
      },
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        // email: [{ required: true, message: "请输入电子邮箱", trigger: "blur" }],
      },
      rules2: {
        email: [{ required: true, message: "请输入电子邮箱", trigger: "blur" }],
      },
      checked: false,
    };
  },
  created() {
    if (localStorage.getItem("isRembered")) {
      this.checked = true;
    }
    if (localStorage.getItem("username")) {
      this.form.name = localStorage.getItem("username");
    }
    if (localStorage.getItem("password")) {
      // console.log(
      //   CryptoJS.AES.decrypt(localStorage.getItem("password"), "meiziku")
      // );
      this.form.password = CryptoJS.AES.decrypt(
        localStorage.getItem("password"),
        "meiziku"
      ).toString(CryptoJS.enc.Utf8);
    }
  },
  methods: {
    ...mapMutations({
      vxSetLoginStatus: "setIsLogin",
      vxSetUser: "setUser",
    }),
    submitForm(formName) {
      this.handleSave();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    async forget() {
      try {
        const res = await forget({
          email: this.form.email,
        });
        console.log(res);
        if (res.code == 1) {
          this.lgShow = "sucss";
        }
      } catch (e) {
        console.log(JSON.parse(e));
        let msg = JSON.parse(e).msg;
        this.$message.error(msg);
      }
    },
    async login() {
      this.isLoading = true;
      try {
        const res = await loginApi({
          username: this.form.name,
          password: this.form.password,
        });
        this.vxSetLoginStatus("logged");
        this.vxSetUser({
          role: res.user_type,
          logo: res.user_logo,
          username: res.username,
          token: res.token,
          uid: res.user_id,
          meizi_down: res.meizi_down,
          dow_key: res.dow_key,
          email: res.email,
        });
        this.isLoading = false;
        this.close();
      } catch (e) {
        this.$message.error(e.data);
        this.isLoading = false;
      }
    },
    close() {
      this.$emit("close");
      this.lgShow = "login";
    },
    toRegister() {
      if (this.$route.name !== "register") {
        this.close();
        this.$router.push({ name: "register" });
      }
    },
    handleSave() {
      if (this.checked) {
        localStorage.setItem("isRembered", this.checked.toString());
        localStorage.setItem("username", this.form.name);
        localStorage.setItem(
          "password",
          CryptoJS.AES.encrypt(this.form.password, "meiziku").toString()
        );
      }
    },
  },
};
